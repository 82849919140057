.upgrade {
  display: flex;
  padding: 10px;
  cursor: pointer;
}

.can-up {
  border: 2px solid #240b36cc;
}

.cant-up {
  border: 2px solid red;
}

.upgrade__image {
  width: 75px;
  height: 75px;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1px) and (max-width: 699px) {
  .upgrade {
    padding: 4px;
    font-size: 6px;
  }

  .can-up {
    border: 0.8px solid #240b36cc;
  }

  .cant-up {
    border: 0.8px solid red;
  }

  .upgrade__image {
    width: 30px;
    height: 30px;
  }

  .text {
    font-size: 5px;
  }
}

@media (min-width: 700px) and (max-width: 911px) {
  .upgrade {
    padding: 5px;
    font-size: 7px;
  }

  .can-up {
    border: 1px solid #240b36cc;
  }

  .cant-up {
    border: 1px solid red;
  }

  .upgrade__image {
    width: 37.5px;
    height: 37.5px;
  }
  .text {
    font-size: 6px;
  }
}

@media (min-width: 912px) and (max-width: 1500px) {
  .upgrade {
    padding: 6.7px;
    border: 0.67px solid black;
    font-size: 8.5px;
  }

  .cant-up {
    border: 1.34px solid #240b36cc;
  }

  .can-up {
    border: 1.34px solid red;
  }

  .upgrade__image {
    width: 50.25px;
    height: 50.25px;
  }
  .text {
    font-size: 7px;
  }
}
