.purchase__container {
  height: 100px;
  width: 100%;
  cursor: pointer;
  display: flex;
  direction: ltr;
}

.cant {
  border-right: 10px solid red;
  border-bottom: 10px solid red;
}
.can {
  border-right: 10px solid #240b36cc;
  border-bottom: 10px solid #240b36cc;
}

.can:hover {
  border-right: 10px solid #240b36;
  border-bottom: 10px solid #240b36;
  background-color: #e8e1e1;
}

.can:active {
  border-right: 10px solid #1c092a;
  border-bottom: 10px solid #1c092a;
  background-color: #d8d0d0;
}

.cant:hover {
  border-right: 10px solid rgb(158, 7, 7);
  border-bottom: 10px solid rgb(158, 7, 7);
  background-color: #e8e1e1;
}

.cant:active {
  border-right: 10px solid rgb(122, 11, 11);
  border-bottom: 10px solid rgb(122, 11, 11);
  background-color: #d8d0d0;
}

.purchase__img--container {
  display: flex;
  width: 30%;
}

.purchase__img {
  max-width: 100%;
  text-align: center;
}

.text__container--purchase {
  width: 80%;
}

.purchase__container h1 {
  font-size: 30px;
}

.purchase__container h2 {
  font-size: 15px;
}

.quanity {
  font-size: 60px;
  text-align: end;
}

@media (min-width: 1px) and (max-width: 699px) {
  .purchase__container {
    height: 40px;
    border-right: 4px solid #240b36cc;
    border-bottom: 4px solid #240b36cc;
  }

  .cant {
    border-right: 4px solid rgb(158, 7, 7);
    border-bottom: 4px solid rgb(158, 7, 7);
  }
  .can {
    border-right: 4px solid #240b36cc;
    border-bottom: 4px solid #240b36cc;
  }

  .can:hover {
    border-right: 4px solid #240b36;
    border-bottom: 4px solid #240b36;
  }

  .can:active {
    border-right: 4px solid #1c092a;
    border-bottom: 4px solid #1c092a;
  }

  .cant:hover {
    border-right: 4px solid rgb(158, 7, 7);
    border-bottom: 4px solid rgb(158, 7, 7);
  }

  .cant:active {
    border-right: 4px solid rgb(122, 11, 11);
    border-bottom: 4px solid rgb(122, 11, 11);
  }
  .purchase__container h1 {
    font-size: 10px;
  }

  .purchase__container h2 {
    font-size: 6px;
  }

  .quanity {
    font-size: 24px;
    text-align: end;
  }
}

@media (min-width: 700px) and (max-width: 911px) {
  .purchase__container {
    height: 50px;
    border-right: 5px solid #240b36cc;
    border-bottom: 5px solid #240b36cc;
  }

  .cant {
    border-right: 5px solid red;
    border-bottom: 5px solid red;
  }
  .can {
    border-right: 5px solid #240b36cc;
    border-bottom: 5px solid #240b36cc;
  }
  .can:hover {
    border-right: 5px solid #240b36;
    border-bottom: 5px solid #240b36;
  }

  .can:active {
    border-right: 5px solid #1c092a;
    border-bottom: 5px solid #1c092a;
  }

  .cant:hover {
    border-right: 5px solid rgb(158, 7, 7);
    border-bottom: 5px solid rgb(158, 7, 7);
  }

  .cant:active {
    border-right: 5px solid rgb(122, 11, 11);
    border-bottom: 5px solid rgb(122, 11, 11);
  }

  .purchase__container h1 {
    font-size: 15px;
  }

  .purchase__container h2 {
    font-size: 7.5px;
  }

  .quanity {
    font-size: 30px;
    text-align: end;
  }
}

@media (min-width: 700px) and (max-width: 1500px) {
  .purchase__container {
    height: 67px;
    border-right: 6.7px solid #240b36cc;
    border-bottom: 6.7px solid #240b36cc;
  }

  .cant {
    border-right: 6.7px solid red;
    border-bottom: 6.7px solid red;
  }
  .can {
    border-right: 6.7px solid #240b36cc;
    border-bottom: 6.7px solid #240b36cc;
  }
  .can:hover {
    border-right: 6.7px solid #240b36;
    border-bottom: 6.7px solid #240b36;
  }

  .can:active {
    border-right: 6.7px solid #1c092a;
    border-bottom: 6.7px solid #1c092a;
  }

  .cant:hover {
    border-right: 6.7px solid rgb(158, 7, 7);
    border-bottom: 6.7px solid rgb(158, 7, 7);
  }

  .cant:active {
    border-right: 6.7px solid rgb(122, 11, 11);
    border-bottom: 6.7px solid rgb(122, 11, 11);
  }

  .purchase__container h1 {
    font-size: 20.1px;
  }

  .purchase__container h2 {
    font-size: 10px;
  }

  .quanity {
    font-size: 40.2px;
    text-align: end;
  }
}
