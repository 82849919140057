.img__container {
  width: 100%;
  height: 100vh;
  background-image: url(../images/dollarsbg.jpg);
  display: flex;
  justify-content: center;
  padding-top: 10%;
  border-left: 10px #141414 solid;
  border-right: 10px #141414 solid;
  padding-top: 100px;
}

.milei__image {
  width: 400px;
  height: 400px;
  transition: all 0.3s ease;
}

.milei__image:hover {
  transform: scale(1.2);
  transition: all 0.3s ease;
  cursor: pointer;
}

.milei__image:active {
  transform: scale(1.1);
  transition: all 0.1s ease;
}

@media (min-width: 1px) and (max-width: 699px) {
  .img__container {
    border-left: 4px #141414 solid;
    border-right: 4px #141414 solid;
    padding-top: 40px;
  }
  .milei__image {
    width: 160px;
    height: 160px;
  }
}

@media (min-width: 700px) and (max-width: 911px) {
  .img__container {
    border-left: 5px #141414 solid;
    border-right: 5px #141414 solid;
    padding-top: 50px;
  }
  .milei__image {
    width: 200px;
    height: 200px;
  }
}

@media (min-width: 912px) and (max-width: 1500px) {
  .img__container {
    border-left: 6.7px #141414 solid;
    border-right: 6.7px #141414 solid;
    padding-top: 67px;
  }
  .milei__image {
    width: 268px;
    height: 268px;
  }
}
