.container {
  position: fixed;
  width: 100%;
  z-index: 999;
}

.text__container {
  height: 100px;
  width: 100%;
  background-color: #6f2a6d;
  text-align: center;
  font-size: 24px;
  color: white;
}

.text__container p {
}

.dps {
  font-size: 16px;
}

@media (min-width: 1px) and (max-width: 699px) {
  .text__container {
    height: 40px;
    font-size: 9.6px;
  }
  .dps {
    font-size: 6.4px;
  }
}

@media (min-width: 700px) and (max-width: 911px) {
  .text__container {
    height: 50px;
    font-size: 12px;
  }
  .dps {
    font-size: 8px;
  }
}

@media (min-width: 912px) and (max-width: 1500px) {
  .text__container {
    height: 67px;
    font-size: 16.08px;
  }
  .dps {
    font-size: 10.72px;
  }
}
